// TODO: resolve cyclical dependencies
/* ============
 * Vuex Store
 * ============
 *
 * The store of the application.
 *
 * http://vuex.vuejs.org/en/index.html
 */

import { createStore, createLogger } from 'vuex'

// Modules
import general from './modules/general/index.js'
import account from './modules/account/index.js'
import language from './modules/language/index.js'
import notifications from './modules/notifications/index.js'
import materials from './modules/materials/index.js'
import modal from './modules/modal/index.js'
import offers from './modules/offers/index.js'
import auth from './modules/auth/index.js'
import exercise from './modules/exercise/index.js'
import courses from './modules/courses/index.js'

const debug = __ENVIRONMENT__ !== 'production'
const modules = {
  notifications,
  language,
  general,
  account,
  materials,
  modal,
  offers,
  auth,
  exercise,
  courses
}

const store = createStore({
  /**
   * Assign the modules to the store.
   */
  modules,

  /**
   * If strict mode should be enabled.
   */
  strict: debug,

  /**
   * Plugins used in the store.
   */
  plugins: debug
    ? [createLogger()]
    : []
})

export default store
