/* ============
 * Modal Mixin
 * ============
 *
 * Mixins are used to share common methods for modal component
 *
 */

import { mapGetters } from 'vuex'

export default {
  /**
   * The methods that the mixin will add.
   */
  methods: {
    open (name) {
      return this.$store.dispatch('modal/open', name)
    },

    close (name) {
      const subtype = 'closed'

      switch (name) {
        case 'CoursesViewChanged':
          this.$trackCustomEvent('catalogs_to_roadmap_popup', { subtype })
          break
      }

      return this.$store.dispatch('modal/close', name)
    },

    scrollWindow () {
      if (!document.querySelector('.modal-container')) return

      const modalHeight = document.querySelector('.modal-container').clientHeight
      const { innerHeight, pageYOffset } = window
      const size = innerHeight - modalHeight

      if (size < 90) {
        const y = -(pageYOffset - 45)
        if (y >= size - 20) {
          this.top = y
        } else {
          this.top = size - 20
        }
      }
    }
  },

  computed: {
    ...mapGetters('modal', {
      registrationTab: 'isRegistrationTab',
      options: 'getOptions'
    })
  },

  mounted () {
    window.addEventListener('scroll', this.scrollWindow)
  },

  beforeDestroy () {
    window.removeEventListener('scroll', this.scrollWindow)
  },

  /**
   * The data that can be used by the page.
   *
   * @returns {Object} The view-model data.
   */
  data () {
    return {
      top: 45
    }
  }
}
