/* ============
 * Mutation types for the offers module
 * ============
 *
 * The mutation types that are available
 * on the offers module.
 */

export const SET_ACTIVE_OFFER = 'SET_ACTIVE_OFFER'
export const UPDATE_PLANS = 'UPDATE_PLANS'
export const PUT_STRIPE = 'PUT_STRIPE'
export const REMOVE_PLANS = 'REMOVE_PLANS'
export const SET_CLOUD_PAYMENTS_PLAN = 'SET_CLOUD_PAYMENTS_PLAN'
export const SET_ONE_MONTH_OFFER_PRICE = 'SET_ONE_MONTH_OFFER_PRICE'
export const SET_PROMO_USER = 'SET_PROMO_USER'
export const REMOVE_SOC_PAY = 'REMOVE_SOC_PAY'
export const SET_TIER_TYPE = 'SET_TIER_TYPE'
export const SET_CLOSING_PAYMENT_COUNT = 'SET_CLOSING_PAYMENT_COUNT'
export const SET_MESSAGE_PARAMS = 'SET_MESSAGE_PARAMS'

export default {
  SET_ACTIVE_OFFER,

  UPDATE_PLANS,
  PUT_STRIPE,
  REMOVE_PLANS,
  SET_CLOUD_PAYMENTS_PLAN,
  SET_ONE_MONTH_OFFER_PRICE,
  SET_PROMO_USER,
  REMOVE_SOC_PAY,
  SET_TIER_TYPE,
  SET_MESSAGE_PARAMS,
  SET_CLOSING_PAYMENT_COUNT
}
