import { cleanUpObject, compose, firstOf, G, pick } from '@2l/utils'
import { instance } from '../plugins/axios'

const isAuthenticated = G('defaults.headers.common.X-Session-Id')

const getErrorResponse = firstOf(
  G('response.data'),
  compose(pick({
    code: firstOf(G('response.status'), G('code')),
    message: G('response.statusText')
  }), cleanUpObject)
)

class BaseProxy {
  /**
   * The constructor of the BaseProxy.
   *
   * @param {string} endpoint   The endpoint being used.
   * @param {Object} parameters The parameters for the request.
   */
  constructor (endpoint, parameters = {}) {
    if (typeof endpoint === 'object') {
      parameters = endpoint
      endpoint = null
    }
    this.$http = instance
    this._endpoint = endpoint || 'api/v12'
    this.parameters = parameters
  }

  /**
   * Returns this._endpoint. It uses to override in subclasses
   */
  get endpoint () {
    return this._endpoint
  }

  /**
   * Returns true if session is exists
   *
   * @return {Boolean}
   */
  isAuthenticated () {
    return !!isAuthenticated(this.$http)
  }

  /**
   * Method used to set the query parameters.
   *
   * @param {Object} parameters The given parameters.
   *
   * @returns {BaseProxy} The instance of the proxy.
   */
  setParameters (parameters) {
    Object.keys(parameters).forEach((key) => {
      this.parameters[key] = parameters[key]
    })

    return this
  }

  /**
   * Method used to set a single parameter.
   *
   * @param {string} parameter The given parameter.
   * @param {*} value The value to be set.
   *
   * @returns {BaseProxy} The instance of the proxy.
   */
  setParameter (parameter, value) {
    this.parameters[parameter] = value

    return this
  }

  /**
   * Method used to remove all the parameters.
   *
   * @param {Array} parameters The given parameters.
   *
   * @returns {BaseProxy} The instance of the proxy.
   */
  removeParameters (parameters) {
    parameters.forEach((parameter) => {
      delete this.parameters[parameter]
    })

    return this
  }

  /**
   * Method used to remove a single parameter.
   *
   * @param {string} parameter The given parameter.
   *
   * @returns {BaseProxy} The instance of the proxy.
   */
  removeParameter (parameter) {
    delete this.parameters[parameter]

    return this
  }

  /**
   * The method used to perform an AJAX-request.
   *
   * @param {string}      requestType The request type.
   * @param {string}      url         The URL for the request.
   * @param {Object|null} data        The data to be send with the request.
   *
   * @returns {Promise} The result in a promise.
   */
  submit (requestType, url, data = null) {
    return new Promise((resolve, reject) => {
      this.$http[requestType](url + this.getParameterString(), data)
        .then(response => resolve(response.data))
        .catch((error) => {
          return reject(getErrorResponse(error))
        })
    })
  }

  /**
   * Method used to fetch all items from the API.
   *
   * @param {Object} params - query parameters
   *
   * @returns {Promise} The result in a promise.
   */
  all (params = {}) {
    return this.submit('get', `/${this.endpoint}`, { params })
  }

  /**
   * Method used to fetch a single item from the API.
   *
   * @param {Int} id - The given identifier.
   * @param {Object} params - query parameters
   *
   * @returns {Promise} The result in a promise.
   */
  find (id, params = {}) {
    return this.submit('get', `/${this.endpoint}/${id}`, { params })
  }

  /**
   * Method used to create an item.
   *
   * @param {Object} item The given item.
   *
   * @returns {Promise} The result in a promise.
   */
  create (item) {
    return this.submit('post', `/${this.endpoint}`, item)
  }

  /**
   * Method used to update an item.
   *
   * @param {int}    id   The given identifier.
   * @param {Object} item The given item.
   *
   * @returns {Promise} The result in a promise.
   */
  update (id, item) {
    return this.submit('put', `/${this.endpoint}/${id}`, item)
  }

  /**
   * Method used to destroy an item.
   *
   * @param {int} id The given identifier.
   *
   * @returns {Promise} The result in a promise.
   */
  destroy (id) {
    return this.submit('delete', `/${this.endpoint}/${id}`)
  }

  /**
   * Method used to transform a parameters object to a parameters string.
   *
   * @returns {string} The parameter string.
   */
  getParameterString () {
    const keys = Object.keys(this.parameters)

    const parameterStrings = keys
      .filter(key => !!this.parameters[key])
      .map(key => `${key}=${this.parameters[key]}`)

    return parameterStrings.length === 0
      ? ''
      : `?${parameterStrings.join('&')}`
  }

  /**
   * lookup uses to detect real url for static resource.
   * use it only for non-production environment
   * */
  async lookup (url, method = 'HEAD') {
    if (!url) { return url }

    const result = await fetch(url, { method, mode: 'no-cors' })
    if (!result.ok || result.status === 404) { return url.replace('dev.appewa.com', 'appewa.com') }

    return url
  }
}

export default BaseProxy
