/* ============
 * State of the account module
 * ============
 *
 * The initial state of the account module.
 */

export default {
  _id: null,
  lang: null,
  activeProfile: null,
  email: null,
  login: null,
  score: null,
  lastName: null,
  firstName: null,
  subscription: null,
  materialAccess: null,
  onboardingFinished: null,
  registerAsAnonymous: null,
  hasAcceptedAccounts: null,
  registerBySocNet: null,
  isVisiblePremiumPromo: false,

  materialViewMode: 'published',
  bill: null,
  unsubscribed: null,
  settings: null,
  userCountryCode: ''
}
