/* ============
 * Main File
 * ============
 *
 * Will initialize the application.
 */

import { createApp, markRaw } from 'vue'
import * as Sentry from '@sentry/vue'
import { createPinia } from 'pinia'
import langList from '@2l/locale/'

/* ============
 * Plugins
 * ============
 *
 * Import and bootstrap the plugins.
 */

import './plugins/vue-speech.js'
import 'normalize-stylus'

import any from './plugins/vue-any'
import { i18n } from './plugins/vue-i18n.js'
import { router } from './plugins/vue-router.js'
import { instance as axios } from './plugins/axios.js'

/* ============
 * Main App
 * ============
 *
 * Last but not least, we import the main application.
 */
import { eql, G, keys, not, when } from '@2l/utils'

import App from './App.vue'
import store from './store/index.js'
import { endTimeCount } from '@/utils/session'
// eslint-disable-next-line
import components from './components/UiComponents';
import { debounce } from 'ts-debounce'
import { PiniaDebounce } from '@pinia/plugin-debounce'

const app = createApp(App)
const pinia = createPinia()
pinia.use(PiniaDebounce(debounce))

const dsn = __SENTRY_DSN__
const release = __SENTRY_RELEASE__ || `ewa-class@${__VERSION__}`
const environment = __ENVIRONMENT__ || 'development'
const getMessage = G('originalException.message')
const sentryRegexp = /database deleted|firebaseError|remote Config|createObjectStore|database connection is closing/gi

Sentry.init({
  app,
  dsn,
  release,
  environment,
  beforeSend (event, hint) {
    const message = getMessage(hint)
    if (message && message.match(sentryRegexp)) { return null }

    return __IS_LOCAL_DEVELOPMENT__ === 'yes' ? null : event
  },

  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Firebase
    /firebase\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    /mc\.yandex\.ru/i,
    /twsesi\.appewa\.com/i
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0, // FIXME: update before deploy to prod
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [new Sentry.Replay()]
})

components.forEach(component => {
  app.component(`${component.name}`, component)
})

app.performance = __ENVIRONMENT__ !== 'production'

const triggerExperimentLayer = async function (experiment_layer, experimentId) {
  await app.config.globalProperties.$triggerExperimentLayer(experiment_layer, experimentId)
}

const acceptedLangs = keys(langList)
const isLangAvailable = lang => acceptedLangs.includes(lang)

const checkExperimentLayer = () => {
  const isaAuth = store.getters['auth/isAuthenticated']
  if (isaAuth) {
    return ['external']
  }

  return ['conversion', 'external']
}

router.beforeEach(async (to, from) => {
  if (to.meta.auth && !store.getters['account/getUserId']) { await store.dispatch('account/find') }

  const language = to?.query.language
  const experimentId = to?.meta?.experimentId || ''
  const experimentLayer = to?.meta?.experiment_layer === 'conversion' ? checkExperimentLayer(to?.meta?.experiment_layer) : to?.meta?.experiment_layer

  await triggerExperimentLayer(experimentLayer, experimentId)

  if (!store.getters['auth/isAuthenticated']) {
    if (isLangAvailable(language)) await store.dispatch('account/setLang', language)

    await store.dispatch('account/detectLang')
  }

  // to bypass unauthenticated and premium user users always
  if (to?.meta?.promo || !to?.meta?.auth) { return }

  if (from?.meta?.title === 'Library') { endTimeCount() }

  if (!store.getters['account/getUserId']) { await store.dispatch('account/find') }

  const hasPremium = store.getters['account/isPremium']
  if (hasPremium) { return }

  const userId = store.getters['account/getUserId']

  if (to.meta.auth && !userId) { return { name: 'auth.index' } }
})

const isUnauthorized = eql('response.status', 401)
const accessForbidden = when({
  'response.status': 403,
  'response.data.code': [
    'trial_used',
    'trial_expired',
    'payment_required',
    'bill_expired'
  ]
})

const requiresAuth = not(eql('currentRoute._value.meta.auth', false))

axios.interceptors.response.use(response => response, error => {
  return new Promise((resolve, reject) => {
    if (isUnauthorized(error) && requiresAuth(router)) { return router.push({ name: 'auth.index' }) }
    if (accessForbidden(error)) { return router.push({ name: 'offers.index' }) }

    reject(error)
  })
})

pinia.use(({ store: piniaStore }) => {
  piniaStore.$router = markRaw(router)
})

app.use(any)
  .use(router)
  .use(store)
  .use(pinia)
  .use(i18n)

app.mount('#app')
