<template>
  <transition class="modal-wrapper" name="fade">
    <div v-if="visible">
        <div
          @click.self="closeModal"
          class="modal"
          :class="`${modalClass}`"
        >
          <div @click="close(component)" class="backdrop" :class="`${modalClass}__backdrop`"></div>
          <div :style="{top: top + 'px'}" class="modal-container" :class="`${modalClass}`">
            <div
              @click="close(component)"
              class="btn-close"
              :class="`${modalClass}`">
            </div>
            <component :is="component"></component>
          </div>
        </div>
    </div>
  </transition>
</template>

<script>
import '@/assets/stylus/components/modal.styl'
import { mapGetters } from 'vuex'
import Modal from '@/mixins/modal'

import { defineAsyncComponent } from 'vue'

export default {
  name: 'modal',

  computed: {
    ...mapGetters('modal', {
      visible: 'isVisible',
      component: 'getComponent',
      modalClass: 'getModalClass'
    })
  },

  methods: {
    closeModal () {
      if (this.component !== 'PromoNotRegistered') { return }

      this.close('PromoNotRegistered')
    }
  },

  mixins: [Modal],

  components: {
    Registration: defineAsyncComponent(() => import('@/components/Form/Registration.vue')),
    Restore: defineAsyncComponent(() => import('@/components/Form/Restore.vue')),
    Info: defineAsyncComponent(() => import('@/components/Form/Info.vue')),
    NavigationOptions: defineAsyncComponent(() => import('@/pages/Library/NavigationOptions.vue')),
    Support: defineAsyncComponent(() => import('@/pages/Account/Support.vue')),
    BannerAccess: defineAsyncComponent(() => import('./BannerAccess.vue')),
    Warning: defineAsyncComponent(() => import('@/components/Warning.vue')),
    WebInfo: defineAsyncComponent(() => import('@/components/WebInfo.vue')),
    Promo: defineAsyncComponent(() => import('@/components/Promo.vue')),
    SubscriptionSuccess: defineAsyncComponent(() => import('@/components/SubscriptionSuccessPopup.vue')),
    PromoNotRegistered: defineAsyncComponent(() => import('@/components/PromoNotRegistered.vue')),
    ClosedForNow: defineAsyncComponent(() => import('@/components/ClosedForNow.vue')),
    NotAvailable: defineAsyncComponent(() => import('@/components/Popups/NotAvailable')),
    AfterReport: defineAsyncComponent(() => import('@/components/Popups/AfterReport')),
    CoursesViewChanged: defineAsyncComponent(() => import('@/components/Popups/CoursesViewChanged'))
  }
}
</script>
