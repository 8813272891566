const mappingCurrency = {
  data () {
    return {
      currenciesList: {
        NN: { language: 'ru', currency: 'RUB', symbol: '₽' },
        BD: { language: 'bn', currency: 'BDT', symbol: '৳' },
        BE: { language: 'nl', currency: 'EUR', symbol: '€' },
        BF: { language: 'fr', currency: 'XOF', symbol: 'CFA' },
        BG: { language: 'bg', currency: 'BGN', symbol: 'лв.' },
        BA: { language: 'bs', currency: 'BAM', symbol: 'KM' },
        BB: { language: 'en', currency: 'BBD' },
        WF: { language: 'fr', currency: 'XPF' },
        BL: { language: 'fr', currency: 'EUR', symbol: '€' },
        BM: { language: 'en', currency: 'BMD' },
        BN: { language: 'ms', currency: 'BND', symbol: '$' },
        BO: { language: 'es', currency: 'BOB', symbol: 'Bs' },
        BH: { language: 'ar', currency: 'BHD', symbol: 'د.ب.' },
        BI: { language: 'fr', currency: 'BIF', symbol: 'FBu' },
        BJ: { language: 'fr', currency: 'XOF', symbol: 'CFA' },
        BT: { language: 'dz', currency: 'BTN' },
        JM: { language: 'en', currency: 'JMD', symbol: '$' },
        BV: { language: 'no', currency: 'NOK', symbol: 'kr' },
        BW: { language: 'en', currency: 'BWP', symbol: 'P' },
        WS: { language: 'sm', currency: 'WST' },
        BQ: { language: 'nl', currency: 'USD', symbol: '$' },
        BR: { language: 'pt', currency: 'BRL', symbol: 'R$' },
        BS: { language: 'en', currency: 'BSD' },
        JE: { language: 'en', currency: 'GBP', symbol: '£' },
        BY: { language: 'be', currency: 'BYR', symbol: 'BYR' },
        BZ: { language: 'en', currency: 'BZD', symbol: '$' },
        RU: { language: 'ru', currency: 'RUB', symbol: '₽.' },
        RW: { language: 'rw', currency: 'RWF', symbol: 'FR' },
        RS: { language: 'sr', currency: 'RSD', symbol: 'дин.' },
        TL: { language: 'pt', currency: 'USD', symbol: '$' },
        RE: { language: 'fr', currency: 'EUR', symbol: '€' },
        TM: { language: 'tk', currency: 'TMT' },
        TJ: { language: 'tg', currency: 'TJS' },
        RO: { language: 'ro', currency: 'RON', symbol: 'RON' },
        TK: { language: 'en', currency: 'NZD', symbol: '$' },
        GW: { language: 'pt', currency: 'XOF', symbol: 'CFA' },
        GU: { language: 'en', currency: 'USD', symbol: '$' },
        GT: { language: 'es', currency: 'GTQ', symbol: 'Q' },
        GS: { language: 'en', currency: 'GBP', symbol: '£' },
        GR: { language: 'el', currency: 'EUR', symbol: '€' },
        GQ: { language: 'es', currency: 'XAF', symbol: 'FCFA' },
        GP: { language: 'fr', currency: 'EUR', symbol: '€' },
        JP: { language: 'ja', currency: 'JPY', symbol: '￥' },
        GY: { language: 'en', currency: 'GYD' },
        GG: { language: 'en', currency: 'GBP', symbol: '£' },
        GF: { language: 'fr', currency: 'EUR', symbol: '€' },
        GE: { language: 'ka', currency: 'GEL', symbol: 'GEL' },
        GD: { language: 'en', currency: 'XCD' },
        GB: { language: 'en', currency: 'GBP', symbol: '£' },
        GA: { language: 'fr', currency: 'XAF', symbol: 'FCFA' },
        SV: { language: 'es', currency: 'USD', symbol: '$' },
        GN: { language: 'fr', currency: 'GNF', symbol: 'FG' },
        GM: { language: 'en', currency: 'GMD' },
        GL: { language: 'kl', currency: 'DKK', symbol: 'kr' },
        GI: { language: 'en', currency: 'GIP' },
        GH: { language: 'en', currency: 'GHS', symbol: 'GH₵' },
        OM: { language: 'ar', currency: 'OMR', symbol: 'ر.ع.' },
        TN: { language: 'ar', currency: 'TND', symbol: 'د.ت.' },
        JO: { language: 'ar', currency: 'JOD', symbol: 'د.أ.' },
        HR: { language: 'hr', currency: 'EUR', symbol: '€' },
        HT: { language: 'fr', currency: 'HTG' },
        HU: { language: 'hu', currency: 'HUF', symbol: 'Ft' },
        HK: { language: 'zh', currency: 'HKD', symbol: '$' },
        HN: { language: 'es', currency: 'HNL', symbol: 'L' },
        HM: { language: 'en', currency: 'AUD', symbol: '$' },
        VE: { language: 'es', currency: 'VEF', symbol: 'Bs.F.' },
        PR: { language: 'es', currency: 'USD', symbol: '$' },
        PS: { language: 'ar', currency: 'ILS', symbol: '₪' },
        PW: { language: 'en', currency: 'USD', symbol: '$' },
        PT: { language: 'pt', currency: 'EUR', symbol: '€' },
        SJ: { language: 'no', currency: 'NOK', symbol: 'kr' },
        PY: { language: 'es', currency: 'PYG', symbol: '₲' },
        IQ: { language: 'ar', currency: 'IQD', symbol: 'د.ع.' },
        PA: { language: 'es', currency: 'PAB', symbol: 'B/.' },
        PF: { language: 'fr', currency: 'XPF' },
        PG: { language: 'en', currency: 'PGK' },
        PE: { language: 'es', currency: 'PEN', symbol: 'S/.' },
        PK: { language: 'ur', currency: 'PKR', symbol: '₨' },
        PH: { language: 'en', currency: 'PHP', symbol: '₱' },
        PN: { language: 'en', currency: 'NZD', symbol: '$' },
        PL: { language: 'pl', currency: 'PLN', symbol: 'zł' },
        PM: { language: 'fr', currency: 'EUR', symbol: '€' },
        ZM: { language: 'en', currency: 'ZMK', symbol: 'ZK' },
        EH: { language: 'ar', currency: 'MAD', symbol: 'د.م.' },
        EE: { language: 'et', currency: 'EUR', symbol: '€' },
        EG: { language: 'ar', currency: 'EGP', symbol: 'ج.م.' },
        ZA: { language: 'en', currency: 'ZAR', symbol: 'R' },
        EC: { language: 'es', currency: 'USD', symbol: '$' },
        IT: { language: 'it', currency: 'EUR', symbol: '€' },
        VN: { language: 'vi', currency: 'VND', symbol: '₫' },
        SB: { language: 'en', currency: 'SBD' },
        ET: { language: 'am', currency: 'ETB', symbol: 'Br' },
        SO: { language: 'so', currency: 'SOS', symbol: 'Ssh' },
        ZW: { language: 'en', currency: 'ZWL', symbol: 'ZWL$' },
        SA: { language: 'ar', currency: 'SAR', symbol: 'ر.س.' },
        ES: { language: 'es', currency: 'EUR', symbol: '€' },
        ER: { language: 'ti', currency: 'ERN', symbol: 'Nfk' },
        ME: { language: 'sr', currency: 'EUR', symbol: '€' },
        MD: { language: 'ro', currency: 'MDL', symbol: 'MDL' },
        MG: { language: 'mg', currency: 'MGA', symbol: 'MGA' },
        MF: { language: 'fr', currency: 'EUR', symbol: '€' },
        MA: { language: 'fr', currency: 'MAD', symbol: 'د.م.' },
        MC: { language: 'fr', currency: 'EUR', symbol: '€' },
        UZ: { language: 'uz', currency: 'UZS', symbol: 'UZS' },
        MM: { language: 'my', currency: 'MMK', symbol: 'K' },
        ML: { language: 'fr', currency: 'XOF', symbol: 'CFA' },
        MO: { language: 'zh', currency: 'MOP', symbol: 'MOP$' },
        MN: { language: 'mn', currency: 'MNT' },
        MH: { language: 'en', currency: 'USD', symbol: '$' },
        MK: { language: 'mk', currency: 'MKD', symbol: 'MKD' },
        MU: { language: 'fr', currency: 'MUR', symbol: 'MURs' },
        MT: { language: 'mt', currency: 'EUR', symbol: '€' },
        MW: { language: 'en', currency: 'MWK' },
        MV: { language: 'dv', currency: 'MVR' },
        MQ: { language: 'fr', currency: 'EUR', symbol: '€' },
        MP: { language: 'en', currency: 'USD', symbol: '$' },
        MS: { language: 'en', currency: 'XCD' },
        MR: { language: 'ar', currency: 'MRO' },
        IM: { language: 'en', currency: 'GBP', symbol: '£' },
        UG: { language: 'en', currency: 'UGX', symbol: 'USh' },
        TZ: { language: 'sw', currency: 'TZS', symbol: 'TSh' },
        MY: { language: 'ms', currency: 'MYR', symbol: 'RM' },
        MX: { language: 'es', currency: 'MXN', symbol: '$' },
        IL: { language: 'he', currency: 'ILS', symbol: '₪' },
        FR: { language: 'fr', currency: 'EUR', symbol: '€' },
        IO: { language: 'en', currency: 'USD', symbol: '$' },
        SH: { language: 'en', currency: 'SHP' },
        FI: { language: 'fi', currency: 'EUR', symbol: '€' },
        FJ: { language: 'en', currency: 'FJD' },
        FK: { language: 'en', currency: 'FKP' },
        FM: { language: 'en', currency: 'USD', symbol: '$' },
        FO: { language: 'fo', currency: 'DKK', symbol: 'kr' },
        NI: { language: 'es', currency: 'NIO', symbol: 'C$' },
        NL: { language: 'nl', currency: 'EUR', symbol: '€' },
        NO: { language: 'nb', currency: 'NOK', symbol: 'kr' },
        NA: { language: 'en', currency: 'NAD', symbol: 'N$' },
        VU: { language: 'bi', currency: 'VUV' },
        NC: { language: 'fr', currency: 'XPF' },
        NE: { language: 'fr', currency: 'XOF', symbol: 'CFA' },
        NF: { language: 'en', currency: 'AUD', symbol: '$' },
        NG: { language: 'en', currency: 'NGN', symbol: '₦' },
        NZ: { language: 'mi', currency: 'NZD', symbol: '$' },
        NP: { language: 'ne', currency: 'NPR', symbol: 'नेरू' },
        NR: { language: 'na', currency: 'AUD', symbol: '$' },
        NU: { language: 'en', currency: 'NZD', symbol: '$' },
        CK: { language: 'en', currency: 'NZD', symbol: '$' },
        XK: { language: 'en', currency: 'EUR', symbol: '€' },
        CI: { language: 'fr', currency: 'XOF', symbol: 'CFA' },
        CH: { language: 'de', currency: 'CHF', symbol: 'CHF' },
        CO: { language: 'es', currency: 'COP', symbol: '$' },
        CN: { language: 'zh', currency: 'CNY', symbol: 'CN¥' },
        CM: { language: 'fr', currency: 'XAF', symbol: 'FCFA' },
        CL: { language: 'es', currency: 'CLP', symbol: '$' },
        CC: { language: 'en', currency: 'AUD', symbol: '$' },
        CA: { language: 'en', currency: 'CAD', symbol: '$' },
        CG: { language: 'fr', currency: 'XAF', symbol: 'FCFA' },
        CF: { language: 'fr', currency: 'XAF', symbol: 'FCFA' },
        CD: { language: 'fr', currency: 'CDF', symbol: 'FrCD' },
        CZ: { language: 'cs', currency: 'CZK', symbol: 'Kč' },
        CY: { language: 'el', currency: 'EUR', symbol: '€' },
        CX: { language: 'en', currency: 'AUD', symbol: '$' },
        CR: { language: 'es', currency: 'CRC', symbol: '₡' },
        CW: { language: 'nl', currency: 'ANG' },
        CV: { language: 'pt', currency: 'CVE', symbol: 'CV$' },
        CU: { language: 'es', currency: 'CUP' },
        SZ: { language: 'en', currency: 'SZL' },
        SY: { language: 'ar', currency: 'SYP', symbol: 'ل.س.' },
        SX: { language: 'nl', currency: 'ANG' },
        KG: { language: 'ky', currency: 'KGS' },
        KE: { language: 'sw', currency: 'KES', symbol: 'Ksh' },
        SS: { language: 'en', currency: 'SSP' },
        SR: { language: 'nl', currency: 'SRD' },
        KI: { language: 'en', currency: 'AUD', symbol: '$' },
        KH: { language: 'km', currency: 'KHR', symbol: '៛' },
        KN: { language: 'en', currency: 'XCD' },
        KM: { language: 'ar', currency: 'KMF', symbol: 'FC' },
        ST: { language: 'pt', currency: 'STD' },
        SK: { language: 'sk', currency: 'EUR', symbol: '€' },
        KR: { language: 'ko', currency: 'KRW', symbol: '₩' },
        SI: { language: 'sl', currency: 'EUR', symbol: '€' },
        KP: { language: 'ko', currency: 'KPW' },
        KW: { language: 'ar', currency: 'KWD', symbol: 'د.ك.' },
        SN: { language: 'fr', currency: 'XOF', symbol: 'CFA' },
        SM: { language: 'it', currency: 'EUR', symbol: '€' },
        SL: { language: 'en', currency: 'SLL' },
        SC: { language: 'fr', currency: 'SCR' },
        KZ: { language: 'kk', currency: 'KZT', symbol: 'тңг.' },
        KY: { language: 'en', currency: 'KYD' },
        SG: { language: 'zh', currency: 'SGD', symbol: '$' },
        SE: { language: 'sv', currency: 'SEK', symbol: 'kr' },
        SD: { language: 'ar', currency: 'SDG', symbol: 'SDG' },
        DO: { language: 'es', currency: 'DOP', symbol: 'RD$' },
        DM: { language: 'en', currency: 'XCD' },
        DJ: { language: 'fr', currency: 'DJF', symbol: 'Fdj' },
        DK: { language: 'da', currency: 'DKK', symbol: 'kr' },
        VG: { language: 'en', currency: 'USD', symbol: '$' },
        DE: { language: 'de', currency: 'EUR', symbol: '€' },
        YE: { language: 'ar', currency: 'YER', symbol: 'ر.ي.' },
        DZ: { language: 'ar', currency: 'DZD', symbol: 'د.ج.' },
        US: { language: 'en', currency: 'USD', symbol: '$' },
        UY: { language: 'es', currency: 'UYU', symbol: '$' },
        YT: { language: 'fr', currency: 'EUR', symbol: '€' },
        UM: { language: 'en', currency: 'USD', symbol: '$' },
        LB: { language: 'ar', currency: 'LBP', symbol: 'ل.ل.' },
        LC: { language: 'en', currency: 'XCD' },
        LA: { language: 'lo', currency: 'LAK' },
        TV: { language: 'en', currency: 'AUD', symbol: '$' },
        TW: { language: 'zh', currency: 'TWD', symbol: 'NT$' },
        TT: { language: 'en', currency: 'TTD', symbol: '$' },
        TR: { language: 'tr', currency: 'TRY', symbol: 'TL' },
        LK: { language: 'si', currency: 'LKR', symbol: 'SL Re' },
        LI: { language: 'de', currency: 'CHF', symbol: 'CHF' },
        LV: { language: 'lv', currency: 'EUR', symbol: '€' },
        TO: { language: 'en', currency: 'TOP', symbol: 'T$' },
        LT: { language: 'lt', currency: 'LTL', symbol: 'Lt' },
        LU: { language: 'lb', currency: 'EUR', symbol: '€' },
        LR: { language: 'en', currency: 'LRD' },
        LS: { language: 'en', currency: 'LSL' },
        TH: { language: 'th', currency: 'THB', symbol: '฿' },
        TF: { language: 'fr', currency: 'EUR', symbol: '€' },
        TG: { language: 'fr', currency: 'XOF', symbol: 'CFA' },
        TD: { language: 'fr', currency: 'XAF', symbol: 'FCFA' },
        TC: { language: 'en', currency: 'USD', symbol: '$' },
        LY: { language: 'ar', currency: 'LYD', symbol: 'د.ل.' },
        VA: { language: 'it', currency: 'EUR', symbol: '€' },
        VC: { language: 'en', currency: 'XCD' },
        AE: { language: 'ar', currency: 'AED', symbol: 'د.إ.' },
        AD: { language: 'ca', currency: 'EUR', symbol: '€' },
        AG: { language: 'en', currency: 'XCD' },
        AF: { language: 'fa', currency: 'AFN', symbol: '؋' },
        AI: { language: 'en', currency: 'XCD' },
        VI: { language: 'en', currency: 'USD', symbol: '$' },
        IS: { language: 'is', currency: 'ISK', symbol: 'kr' },
        IR: { language: 'fa', currency: 'IRR', symbol: '﷼' },
        AM: { language: 'hy', currency: 'AMD', symbol: 'դր.' },
        AL: { language: 'sq', currency: 'ALL', symbol: 'Lek' },
        AO: { language: 'pt', currency: 'AOA' },
        AQ: { language: 'en', currency: 'RUB', symbol: '₽.' },
        AS: { language: 'en', currency: 'USD', symbol: '$' },
        AR: { language: 'es', currency: 'ARS', symbol: '$' },
        AU: { language: 'en', currency: 'AUD', symbol: '$' },
        AT: { language: 'de', currency: 'EUR', symbol: '€' },
        AW: { language: 'nl', currency: 'AWG' },
        IN: { language: 'hi', currency: 'INR', symbol: 'টকা' },
        AX: { language: 'sv', currency: 'EUR', symbol: '€' },
        AZ: { language: 'az', currency: 'AZN', symbol: 'ман.' },
        IE: { language: 'en', currency: 'EUR', symbol: '€' },
        ID: { language: 'id', currency: 'IDR', symbol: 'Rp' },
        UA: { language: 'uk', currency: 'UAH', symbol: '₴' },
        QA: { language: 'ar', currency: 'QAR', symbol: 'ر.ق.' },
        MZ: { language: 'pt', currency: 'MZN', symbol: 'MTn' }
      }
    }
  },
  methods: {
    getCountryData (currency) {
      return (
        Object.values(this.currenciesList)
          .find(val => val.currency === currency)?.symbol
      )
    }
  }
}

export default mappingCurrency
