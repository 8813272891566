/* ============
 * Account Transformer
 * ============
 *
 * The transformer for the account.
 */

import {
  G, set, pick, identity, firstOf, indexBy
} from '@2l/utils'
import Transformer from './Transformer.js'
import { tracker } from '@/plugins/vue-any'
import { getCookie } from '../utils/session'

const accountFields = [
  'bill',
  '_id',
  'lang',
  'activeProfile',
  'login',
  'email',
  'score',
  'materialAccess',
  'subscription',
  'navigation',
  'learningMaterials',
  'registerAsAnonymous',
  'registerBySocNet',
  'profiles',
  'createDate'
]

const checkCoursesView = () => {
  if (!getCookie('coursesType') || getCookie('coursesType') === 'courses' || tracker.getConfigStringValue('courses_type', '') === 'courses') {
    document.cookie = `coursesType=${tracker.getConfigStringValue('courses_type', '')}`
    return true
  }

  if (tracker.getConfigStringValue('courses_type', 'roadmap')) {
    document.cookie = 'coursesType=roadmap'
  }

  return false
}

const isRoadMapAllowed = (user) => {
  const idx = indexBy('lang', user.profiles || [])
  const profile = idx[user.activeProfile] || idx.en || {}

  return profile.coursesView === 'roadmap'
}

const buildAccount = pick(
  ...accountFields,
  {
    id: G('_id'),
    hasAcceptedAccounts: G('hasAcceptedAccounts'),
    onboardingFinished: G('onboardingFinished'),
    'settings.name': firstOf(G('settings.name'), identity(' ')),
    'settings.avatar': firstOf(G('settings.avatar'), identity(''))
  }
)

export default class AccountTransformer extends Transformer {
  /**
   * Method used to transform a fetched account.
   *
   * @param account The fetched account.
   *
   * @returns {Object} The transformed account.
   */
  static fetch (user) {
    const account = buildAccount(user)
    account.roadMapAllowed = isRoadMapAllowed(user)
    account.coursesUser = checkCoursesView()

    const [firstName, lastName] = account.settings.name.split(' ')
    Object.assign(account.settings, { firstName, lastName })

    set(account, 'meta.analytics', [['set', 'userId', account.id]])

    return account
  }

  /**
   * Method used to transform a send account.
   *
   * @param account The account to be send.
   *
   * @returns {Object} The transformed account.
   */
  static send (account) {
    return {
      email: account.email,
      login: account.login
    }
  }
}
