/* ============
 * Actions for the offers module
 * ============
 *
 * The actions that are available on the
 * offers module.
 */

import utils from '@2l/utils'

import SubscriptionProxy from '@/proxies/SubscriptionProxy.js'
import SubscriptionPaypalProxy from '@/proxies/SubscriptionPaypalProxy.js'
import PlanProxy from '@/proxies/PlanProxy.js'
import CheckoutProxy from '@/proxies/CheckouProxy.js'

import * as types from './mutation-types.js'

const subscriptionProxy = new SubscriptionProxy()
const planProxy = new PlanProxy()
const subscriptionPaypalProxy = new SubscriptionPaypalProxy()

const { detect, compact } = utils

const routerPrefix = __ROUTER_PREFIX__ || ''
const baseUrl = __IS_LOCAL_DEVELOPMENT__ === 'yes' ? 'https://local.appewa.com/' : __WEB_APP_URL__
const appUrl = new URL(routerPrefix, baseUrl)

export default {
  getPlanProxy,
  setActiveOffer,
  setSubscriptionStripe,
  removePlanCards,
  setSubscriptionPaypal,
  setCloudPaymentPlan,
  getOneMonthOfferPlan,
  setPromoUser,
  removeSocPay,
  goToCheckout,
  setClosingPaymentCount
}

export async function setSubscriptionStripe ({ commit }, data) {
  const response = await subscriptionProxy.subscribe(data)
  commit(types.PUT_STRIPE, response)
}

export async function getPlanProxy ({ commit }, params) {
  commit(types.REMOVE_PLANS)
  commit(types.SET_TIER_TYPE)
  commit(types.SET_MESSAGE_PARAMS)
  const response = await planProxy.list(params)
  commit(types.UPDATE_PLANS, response.result.plans)
}

export async function setActiveOffer ({ commit, state }, offerId) {
  commit(types.SET_ACTIVE_OFFER, detect({ name: offerId }, state.offerCards))
}

export function removePlanCards ({ commit }) {
  commit(types.REMOVE_PLANS)
}

// eslint-disable-next-line no-unused-vars
export async function setSubscriptionPaypal ({ commit }, data) {
  await subscriptionPaypalProxy.subscribe(data)
}

export async function setCloudPaymentPlan ({ commit }, planId) {
  const response = await planProxy.info(planId)
  commit(types.SET_CLOUD_PAYMENTS_PLAN, response.result.plan)
}

export async function getOneMonthOfferPlan ({ commit }, planId) {
  const response = await planProxy.info(planId)
  commit(types.SET_ONE_MONTH_OFFER_PRICE, response.result.plan)
}

export function setPromoUser ({ commit }) {
  commit(types.SET_PROMO_USER)
}

export function removeSocPay ({ commit }) {
  commit(types.REMOVE_SOC_PAY)
}

// eslint-disable-next-line
export async function goToCheckout({ commit, dispatch, rootGetters }, data = {}) {
  let { planId, type, messageId, applePay, path = 'success', orderStyle, checkoutType } = data
  planId = planId.replaceAll('-', '.')
  const pathArr = compact(appUrl.pathname.split('/'))
  pathArr.push(path)
  const url = new URL(pathArr.join('/'), appUrl)
  url.searchParams.set('meta', `${planId},${type}`)

  const currency = rootGetters['offers/getOfferCurrency']
  const redirectURL = url.href

  const params = {
    planId,
    currency,
    redirectURL,
    messageId,
    orderStyle,
    checkoutType
  }

  if (applePay) {
    params.designType = '2'
  }

  const checkoutProxy = new CheckoutProxy(params)

  try {
    const { result } = await checkoutProxy.getLink()
    window.location = new URL(result).href
  } catch (e) {
    const options = {
      modalClass: 'web-info',
      responseMessage: 'label_error'
    }
    await dispatch('modal/setOptions', options, { root: true })
    await dispatch('modal/open', 'WebInfo', { root: true })
  }
}

export async function setClosingPaymentCount ({ commit }) {
  commit(types.SET_CLOSING_PAYMENT_COUNT)
}
