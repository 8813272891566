export const user = [
  'lang',
  'activeProfile',
  'login',
  'email',
  'score',
  'onboardingFinished',
  'registerAsAnonymous',
  'registerBySocNet',
  'hasAcceptedAccounts',
  'bill(subscriptionPlan(type), startDate, endDate, renewable, acknowledged)',
  'materialAccess',
  'subscription',
  `settings(
      age,
      ageRange,
      contentAgeRating,
      languageAccent,
      motivation,
      learningDirection,
      navigation,
      contactEmail,
      name,
      notificationTime)`,
  'contentAgeRating',
  'languageAccent',
  'languageLevel',
  'navigation',
  `learningMaterials(
    _id,
    materialId,
    type,
    material(
      _id,
      genres(_id,origin,label),
      origin,
      image,
      languageLevel,
      isOriginal
    )
  )`,
  'profiles',
  'createDate'
].join(',')

export default { user }
