/* ============
 * State of the offers module
 * ============
 *
 * The initial state of the offers module.
 */

export default {
  onMonthPrice: null,
  cloudPaymentPlan: {},
  offer: null,
  token: null,
  promoUser: '',
  tierType: null,
  isFirstTimeClosingPayment: true,

  offerCards: [],
  // TODO: Static data should be removed from store.
  plans: [
    {
      title: 'trigger_lessons',
      class: 'plans_avatar_one'
    },
    {
      title: 'trigger_library',
      class: 'plans_avatar_two'
    },
    {
      title: 'trigger_phrases',
      class: 'plans_avatar_three'
    }
  ],
  cardReviews: [
    {
      nik_name: 'Anutel',
      rating_count: 5,
      text: 'store_review_1',
      class: 'reviews_user_one'
    },
    {
      nik_name: 'Жанысбекова Римма',
      rating_count: 5,
      text: 'store_review_2',
      class: 'reviews_user_two'
    },
    {
      nik_name: 'ANASTASIYA B',
      rating_count: 5,
      text: 'store_review_3',
      class: 'reviews_user_three'
    },
    {
      nik_name: 'mrzhek',
      rating_count: 5,
      text: 'store_review_4',
      class: 'reviews_user_four'
    },
    {
      nik_name: '1378457475',
      rating_count: 5,
      text: 'store_review_5',
      class: 'reviews_user_five'
    }
  ],
  info: [
    {
      title: 'trigger_trial_title',
      description: 'trigger_trial_descr',
      class: 'icon-free'
    },
    {
      title: 'trigger_security_title',
      description: 'trigger_security_descr',
      class: 'icon-security'
    },
    {
      title: 'trigger_refund_title',
      description: 'trigger_refund_descr',
      class: 'icon-dollar'
    }
  ],
  infoCheckout: [
    {
      title: 'trigger_trial_title',
      description: 'trigger_trial_descr',
      class: 'icon-free-v2'
    },
    {
      title: 'trigger_refund_title',
      description: 'trigger_refund_descr',
      class: 'icon-dollar-v2'
    },
    {
      title: 'trigger_security_title',
      description: 'trigger_security_descr',
      class: 'icon-security-v2'
    }
  ],
  infoCheckoutNoTrial: [
    {
      title: 'trigger_refund_title',
      description: 'trigger_refund_descr',
      class: 'icon-dollar-v2'
    },
    {
      title: 'trigger_security_title',
      description: 'trigger_security_descr',
      class: 'icon-security-v2'
    }
  ],
  infoCheckoutNoTrialTwoCheckout: [
    {
      title: 'web_2checkout_7days_trial_free_1',
      description: 'web_2checkout_7days_trial_free_2',
      class: 'icon-free-v2'
    },
    {
      title: 'trigger_refund_title',
      description: 'trigger_refund_descr',
      class: 'icon-dollar-v2'
    },
    {
      title: 'trigger_security_title',
      description: 'trigger_security_descr',
      class: 'icon-security-v2'
    }
  ],
  storeCards: [
    {
      subtype: 'appStoreTapped',
      class: 'app-image',
      ratingValue: 4.9,
      ratingCount: 134.5,
      link: 'https://app.adjust.com/noi4yb0?deep_link=ewa%3A%2F%2F&fallback=https%3A%2F%2Fapps.apple.com%2Fapp%2Fewa-learn-english-spanish%2Fid1200778841'
    },
    {
      type: 'google',
      subtype: 'gplayTapped',
      class: 'gplay-image ',
      ratingValue: 4.7,
      ratingCount: 457,
      link: 'https://app.adjust.com/noi4yb0?deep_link=ewa%3A%2F%2F&fallback=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.ewa.ewaapp'
    }
  ],
  widgets: [
    {
      class: 'widget_image-one',
      description: 'adv_popular_films'
    },
    {
      class: 'widget_image-two',
      description: 'adv_many_books'
    },
    {
      class: 'widget_image-three',
      description: 'adv_language_classes'
    }
  ],
  infoCheckoutV4: [
    {
      title: 'trigger_trial_title',
      description: 'trigger_trial_descr',
      class: 'icon-free-v4'
    },
    {
      title: 'onboard_guarantee_refund',
      description: 'trigger_refund_descr',
      class: 'icon-dollar-v4'
    },
    {
      title: 'trigger_security_title',
      description: 'trigger_security_descr',
      class: 'icon-security-v4'
    }
  ],
  applicationIcons: [
    {
      title: 'App of the day',
      class: 'app-day'
    },
    {
      title: 'Editor’s choice',
      class: 'choice'
    },
    {
      title: 'AppStore rating',
      class: 'app-rating'
    },
    {
      title: 'PlayMarket rating',
      class: 'play-market'
    },
    {
      title: 'Apps We Love',
      class: 'app-love'
    },
    {
      title: 'App of The Week',
      class: 'app-week'
    }
  ],
  infoPromoDay: [
    {
      title: 'onboard_guarantee_refund',
      description: 'trigger_refund_descr',
      class: 'icon-dollar-v4'
    },
    {
      title: 'trigger_security_title',
      description: 'trigger_security_descr',
      class: 'icon-security-v4'
    }
  ],
  paymentCards: [
    {
      name: 'web_babbel_credit_card',
      class: 'visa-pay'
    },
    {
      name: 'PayPal',
      class: 'paypal'
    },
    {
      name: 'ApplePay / GooglePay',
      class: 'social-pay'
    }
  ],
  infoBabbel: [
    {
      title: 'trigger_trial_title',
      description: 'trigger_trial_descr',
      class: 'icon-free'
    },
    {
      title: 'trigger_refund_title',
      description: 'trigger_refund_descr',
      class: 'icon-dollar'
    }
  ]
}
